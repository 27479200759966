




































import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import {documentsNamespace} from "@store/documents";
import {DocumentsActionTypes} from "@store/documents/Types";
import GetStoreMixin from "../../mixins/GetStore";
import {IDocumentKit} from "@store/documents/Interface";
import {worksNamespace} from "@store/works";
import {IWork} from "@store/works/Interface";
import {WorksActionTypes} from "@store/works/Types";
import Debounce from "@/decorators/Debounce";


@Component({
  name: "WorkListComplect",
  components: {
    'v-work-complect-aside': () => import('@views/workList/components/WorkComplectAside.vue'),
    'v-work-card': () => import('@views/workList/components/WorkCard.vue'),
    'v-no-content': () => import('@/components/NoContent.vue'),
  },
})
export default class WorkListComplect extends Mixins(GetStoreMixin) {

  @documentsNamespace.Action(DocumentsActionTypes.A_DOCUMENT_KITS_ALL)
  private fetchDocumentKits!: (payload: { project_id: number }) => Promise<void>

  @documentsNamespace.State('documentKits')
  private documentKits!: IDocumentKit[]

  @worksNamespace.State('works')
  private worksList!: IWork[]

  @worksNamespace.Action(WorksActionTypes.A_WORKS)
  private fetchWorks!: (params) => Promise<void>

  @Prop(String) readonly sortValue: string | null | undefined
  @Prop(String) readonly search: string | null | undefined

  private insidePage: number = 1
  private activeComplect: IDocumentKit | null = null
  private loadingWork: boolean = true
  private loadingComplects: boolean = true


  async mounted() {
    await this.fetchDocumentKits({project_id: this.selectedProject})
    this.loadingComplects = false

    if (this.documentKits.length) {
      this.changeActiveComplect(this.documentKits[0])
    }
  }

  private changeActiveComplect(complect: IDocumentKit) {
    this.activeComplect = complect
    this.changePage()
  }

  private changePage() {
    this.loadingWork = true

    let params = {
      project: this.selectedProject,
      insidePage: this.insidePage,
      documentKitId: this.activeComplect?.id ?? null,
      search: this.search,
    }

    if(this.sortValue){
      let [direction, field] = this.sortValue.split('_')
      params[field + 'Sort'] = direction
    }

    this.fetchWorks(params).finally(() => {
      this.loadingWork = false
      this.$scrollToTop()
    })
  }

  @Watch('sortValue')
  onChildChanged() {
    this.insidePage = 1
    this.changePage()
  }

  @Watch('search')
  @Debounce(1000)
  private changeSearch() {
    if (typeof this.search === 'string'
      && (this.search.length > 3 || this.search.length === 0)) {
      this.insidePage = 1
      this.changePage()
    }
  }
}
